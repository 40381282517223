.article-body {
    font-family: Arial, Helvetica, sans-serif;

    h1 {
        line-height: 46px;
    }

    h4 {
        margin-bottom: 16px;
        margin-top: 24px;
        font-size: 26px;
        line-height: 36px;
    }

    p {
        font-size: 18px;
        line-height: 30px;
    }
}

@include media-breakpoint-up(xxl) {
    .article-page-container {
        max-width: 1300px;
    }
}
