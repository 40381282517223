.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    // position: fixed;
}
  
.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #eee;
    z-index: 9999;
    opacity: 0.8;
}
  
.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}
  
.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 3px;
    border-color: #fff;
    border-top-color: #3688fc;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
    opacity: 1 !important;
}
  
@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}